import React, { useState, useEffect, useCallback } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

interface ImageModalProps {
  images: string[];
  currentIndex: number;
  isOpen: boolean;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ images, currentIndex, isOpen, onClose }) => {
  const [index, setIndex] = useState(currentIndex);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const handlePrevious = useCallback(() => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
    setIsLoading(true);
    setError(false);
  }, [images.length]);

  const handleNext = useCallback(() => {
    setIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
    setIsLoading(true);
    setError(false);
  }, [images.length]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isOpen) return;

      switch (event.key) {
        case 'ArrowLeft':
          handlePrevious();
          break;
        case 'ArrowRight':
          handleNext();
          break;
        case 'Escape':
          onClose();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, handlePrevious, handleNext, onClose]);

  useEffect(() => {
    setIndex(currentIndex);
  }, [currentIndex]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/90 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="relative w-full h-full flex items-center justify-center p-4">
        {/* Кнопка закрытия */}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-white/50 hover:text-white transition-colors"
        >
          <X size={32} />
        </button>

        {/* Кнопка "Предыдущее изображение" */}
        <button 
          onClick={handlePrevious}
          className="absolute left-4 text-white/50 hover:text-white transition-colors"
        >
          <ChevronLeft size={48} />
        </button>

        {/* Изображение */}
        <img
          src={images[index]}
          alt={`Gallery ${index + 1}`}
          className={`max-h-[90vh] max-w-[90vw] object-contain transition-opacity duration-300 ${
            isLoading ? 'opacity-0' : 'opacity-100'
          }`}
          onLoad={() => setIsLoading(false)}
          onError={() => {
            setError(true);
            setIsLoading(false);
          }}
        />

        {/* Индикатор загрузки */}
        {isLoading && !error && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
          </div>
        )}

        {/* Сообщение об ошибке */}
        {error && (
          <div className="text-white text-lg">Failed to load image</div>
        )}

        {/* Кнопка "Следующее изображение" */}
        <button 
          onClick={handleNext}
          className="absolute right-4 text-white/50 hover:text-white transition-colors"
        >
          <ChevronRight size={48} />
        </button>

        {/* Счётчик изображений */}
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white/75">
          {index + 1} / {images.length}
        </div>
      </div>
    </div>
  );
};

export default ImageModal;