import React, { useState, useRef, useEffect } from 'react';
import { X, Play, Pause, SkipBack, SkipForward, Volume2, VolumeX } from 'lucide-react';
import { Album } from './types';

interface EnhancedAudioPlayerProps {
  album: Album;
  albums: Album[];
  isOpen: boolean;
  onClose: () => void;
  onAlbumChange?: (album: Album) => void;
}

const EnhancedAudioPlayer: React.FC<EnhancedAudioPlayerProps> = ({ 
  album, 
  albums, 
  isOpen, 
  onClose,
  onAlbumChange 
}) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [currentAlbumIndex, setCurrentAlbumIndex] = useState(albums.findIndex(a => a.id === album.id));
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [playbackStatus, setPlaybackStatus] = useState<'playing' | 'paused' | 'loading' | 'error'>('paused');
  const audioRef = useRef<HTMLAudioElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);

  const currentAlbum = albums[currentAlbumIndex];

  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  useEffect(() => {
    if (audioRef.current && isPlaying) {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.error('Playback error:', error);
          setPlaybackStatus('error');
          // Повторная попытка воспроизведения
          setTimeout(() => {
            if (audioRef.current) {
              audioRef.current.play()
                .then(() => setPlaybackStatus('playing'))
                .catch(e => {
                  console.error('Retry failed:', e);
                  setPlaybackStatus('error');
                });
            }
          }, 1000);
        });
      }
    } else if (audioRef.current && !isPlaying) {
      audioRef.current.pause();
      setPlaybackStatus('paused');
    }
  }, [isPlaying, currentTrackIndex, currentAlbumIndex]);

  useEffect(() => {
    const newAlbumIndex = albums.findIndex(a => a.id === album.id);
    if (newAlbumIndex !== -1) {
      setCurrentAlbumIndex(newAlbumIndex);
      setCurrentTrackIndex(0);
    }
  }, [album, albums]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handlePrevious = () => {
    if (currentTime > 3) {
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
      }
    } else {
      setCurrentTrackIndex(prev => {
        if (prev === 0) {
          // Переход к последнему треку предыдущего альбома
          if (currentAlbumIndex > 0) {
            const prevAlbumIndex = currentAlbumIndex - 1;
            if (onAlbumChange) {
              onAlbumChange(albums[prevAlbumIndex]);
            }
            setCurrentAlbumIndex(prevAlbumIndex);
            return albums[prevAlbumIndex].tracks.length - 1;
          }
          return prev;
        }
        return prev - 1;
      });
    }
    setIsPlaying(true);
  };

  const handleNext = () => {
    const isLastTrack = currentTrackIndex === currentAlbum.tracks.length - 1;
    const isLastAlbum = currentAlbumIndex === albums.length - 1;
    
    if (isLastTrack) {
      if (isLastAlbum) {
        // Если это последний трек последнего альбома, возвращаемся к первому альбому
        if (onAlbumChange) {
          onAlbumChange(albums[0]);
        }
        setCurrentAlbumIndex(0);
        setCurrentTrackIndex(0);
      } else {
        // Переход к следующему альбому
        const nextAlbumIndex = currentAlbumIndex + 1;
        if (onAlbumChange) {
          onAlbumChange(albums[nextAlbumIndex]);
        }
        setCurrentAlbumIndex(nextAlbumIndex);
        setCurrentTrackIndex(0);
      }
    } else {
      // Переход к следующему треку в текущем альбоме
      setCurrentTrackIndex(prev => prev + 1);
    }
    setIsPlaying(true);
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration);
    }
  };

  const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (progressBarRef.current && audioRef.current) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const pos = (e.clientX - rect.left) / rect.width;
      audioRef.current.currentTime = pos * duration;
    }
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
    if (newVolume === 0) {
      setIsMuted(true);
    } else if (isMuted) {
      setIsMuted(false);
    }
  };

  const toggleMute = () => {
    if (audioRef.current) {
      if (isMuted) {
        audioRef.current.volume = volume;
      } else {
        audioRef.current.volume = 0;
      }
      setIsMuted(!isMuted);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="w-full max-w-2xl bg-gray-900 text-white rounded-lg">
        <div className="flex justify-between items-center p-4 border-b border-gray-800">
          <h2 className="text-2xl font-bold">{currentAlbum.title}</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={24} />
          </button>
        </div>
        
        <div className="p-6">
          {/* Информация о текущем треке */}
          <div className="mb-6">
            <div className="flex items-center gap-4 mb-4">
              <img 
                src={currentAlbum.image} 
                alt={currentAlbum.title} 
                className="w-24 h-24 rounded-lg"
              />
              <div>
                <h3 className="text-lg font-semibold mb-2">{currentTrackIndex + 1}. {currentAlbum.tracks[currentTrackIndex].title}</h3>
                <p className="text-gray-400">{formatTime(currentTime)} / {formatTime(duration)}</p>
                {playbackStatus === 'loading' && (
                  <div className="text-yellow-400">Loading...</div>
                )}
                {playbackStatus === 'error' && (
                  <div className="text-red-500">Error playing track</div>
                )}
              </div>
            </div>
            
            {/* Прогресс-бар */}
            <div 
              ref={progressBarRef}
              className="h-1 bg-gray-700 rounded cursor-pointer"
              onClick={handleProgressBarClick}
            >
              <div 
                className="h-full bg-yellow-400 rounded"
                style={{ width: `${(currentTime / duration) * 100 || 0}%` }}
              />
            </div>
          </div>

          {/* Список треков альбома */}
          <div className="mb-6 max-h-48 overflow-y-auto">
            {currentAlbum.tracks.map((track, index) => (
              <div 
                key={track.id}
                className={`flex items-center justify-between p-2 rounded cursor-pointer ${
                  index === currentTrackIndex ? 'bg-gray-800' : 'hover:bg-gray-800'
                }`}
                onClick={() => {
                  setCurrentTrackIndex(index);
                  setIsPlaying(true);
                }}
              >
                <div className="flex items-center">
                  <span className="w-8 text-gray-400">{index + 1}.</span>
                  <span>{track.title}</span>
                </div>
                <span className="text-gray-400">{track.duration}</span>
              </div>
            ))}
          </div>

          {/* Элементы управления */}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <button 
                onClick={handlePrevious}
                className="p-2 hover:text-yellow-400 transition-colors"
              >
                <SkipBack size={24} />
              </button>
              
              <button 
                onClick={handlePlayPause}
                className="p-2 hover:text-yellow-400 transition-colors"
              >
                {isPlaying ? <Pause size={32} /> : <Play size={32} />}
              </button>
              
              <button 
                onClick={handleNext}
                className="p-2 hover:text-yellow-400 transition-colors"
              >
                <SkipForward size={24} />
              </button>
            </div>

            {/* Управление громкостью */}
            <div className="flex items-center gap-2">
              <button onClick={toggleMute}>
                {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
              </button>
              <input 
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={isMuted ? 0 : volume}
                onChange={handleVolumeChange}
                className="w-24"
              />
            </div>
          </div>
        </div>

        <audio 
          ref={audioRef}
          src={currentAlbum.tracks[currentTrackIndex].url}
          onTimeUpdate={handleTimeUpdate}
          onEnded={handleNext}
        />
      </div>
    </div>
  );
};

export default EnhancedAudioPlayer;